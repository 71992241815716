<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <CommonBreadcrumb :show-title="true" />
                <div class="header-links">
                    <a href="javascript:" class="link text-muted" @click="goAws('athena')">Athena Console <i class="icon-link"></i></a>
                </div>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-aside -->
            <SandboxSideBar />
            <!-- page-contents -->
            <div class="page-contents">
                <div class="table-container">
                    <div class="table table-bordered">
                        <table>
                            <colgroup>
                                <col style="width: auto" />
                                <col style="width: 260px" />
                                <col style="width: 258px" />
                                <col style="width: 48px" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="table-cell"><span class="cell-title">실행 ID</span></div>
                                    </th>
                                    <th>
                                        <div class="table-cell"><span class="cell-title">쿼리</span></div>
                                    </th>
                                    <th>
                                        <div class="table-cell"><span class="cell-title">시작시간</span></div>
                                    </th>
                                    <th>
                                        <div class="table-cell"><span class="cell-title">상태</span></div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="renderItems.length === 0">
                                    <td colspan="4" style="text-align: center">
                                        <div class="table-cell">-</div>
                                    </td>
                                </tr>

                                <tr v-for="(item, index) in renderItems" :key="index">
                                    <td>
                                        <div class="table-cell">
                                            <router-link :to="{name: 'AthenaView', params: {id: item.id}}" class="cell-text text-blue">{{
                                                item.id
                                            }}</router-link>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table-cell">
                                            <span class="cell-text">
                                                <p class="swcc-text-skip" style="width: 200px" :title="item.query">{{ item.query }}</p>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table-cell">
                                            <span class="cell-text text-ellipsis">{{ timestampToDateFormat(item.start, 'yyyy.MM.dd hh:mm') }}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table-cell">
                                            <strong v-if="item.status === 'SUCCEEDED'" class="cell-text text-icon text-primary"
                                                >완료 <i class="icon-check"></i
                                            ></strong>
                                            <strong v-else class="cell-text text-icon">실패</strong>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <CommonPagination :paging="paging" :page-func="pageFunc" />
                    <!-- pagination -->
                </div>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <div v-if="!queryToggle" class="page-bottom bottom-fixed">
            <div>
                <div class="bottom-search-container bottom-component">
                    <div class="bottom-search">
                        <div class="search-column column-form">
                            <input v-model.trim="keyword" type="search" placeholder="최근 쿼리를 검색하세요." @keyup.enter="searchKeyword" />
                        </div>
                        <div class="search-column column-button">
                            <button class="btn-icon" @click="searchKeyword"><i class="icon-search"></i></button>
                        </div>
                    </div>
                    <div class="bottom-side">
                        <button class="btn-action btn-primary" @click="queryToggle = true"><span class="text">간편 쿼리 생성</span></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- //page-bottom -->

        <AthenaQuery v-if="queryToggle" v-model:toggle="queryToggle" @execQuery="execQuery" />
    </main>
</template>

<script>
import {computed, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import {getValueByQuery} from '@/assets/js/route.utils';
import {getItems, lengthCheck, paginate, timestampToDateFormat} from '@/assets/js/common.utils';
import SandboxSideBar from '@/components/sandbox/SandboxSideBar';
import CommonPagination from '@/components/common/CommonPagination';
import ApiService from '@/assets/js/api.service';
import {goAws} from '@/assets/js/modules/aws/module';
import AthenaQuery from '@/components/sandbox/AthenaQuery';

export default {
    name: 'Athena',
    components: {AthenaQuery, CommonPagination, SandboxSideBar, CommonBreadcrumb},
    setup: function () {
        const route = useRoute();
        const router = useRouter();

        const items = ref([]);

        const keyword = ref(getValueByQuery(route.query, 'search', false, ''));
        const filters = reactive({search: getValueByQuery(route.query, 'search', false, '')});

        const filteredItems = computed(() => {
            if (filters.search) {
                return items.value.filter(x => x.id.includes(filters.search) || x.query.includes(filters.search));
            }
            return items.value;
        });

        const paging = reactive({
            pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
            pageSize: 10,
            totalCount: computed(() => filteredItems.value.length),
        });

        const renderItems = computed(() => paginate(filteredItems.value, paging.pageNo, paging.pageSize));
        const currentQuery = computed(() => route.query);

        const queryToggle = ref(false);

        const getAthenaLogList = () => {
            ApiService.query('/v1/aws/athena/logs', {}).then(res => {
                if (lengthCheck(res)) {
                    const logs = getItems(res);
                    logs.sort((a, b) => {
                        if (a.start > b.start) {
                            return -1;
                        } else if (a.start === b.start) {
                            return 0;
                        } else {
                            return 1;
                        }
                    });
                    items.value = logs;
                }
            });
        };

        const pageFunc = pageNo => {
            router.push({query: {...currentQuery.value, pageNo}});
        };

        const searchKeyword = () => {
            router.push({query: {search: keyword.value}});
        };

        // 다시 읽어오기
        const execQuery = () => {
            getAthenaLogList();
            router.push({query: {}});
        };

        watch(
            () => route.query,
            () => {
                if (route.name === 'Athena') {
                    paging.pageNo = route.query.pageNo ? parseInt(route.query.pageNo) : 1;
                    filters.search = getValueByQuery(route.query, 'search', false, '');
                }
            },
        );

        getAthenaLogList();

        return {
            keyword,
            renderItems,
            paging,
            queryToggle,
            pageFunc,
            searchKeyword,
            goAws,
            execQuery,
            timestampToDateFormat,
        };
    },
};
</script>
