<template>
    <div class="layer-container" id="layer-query">
        <!-- layer -->
        <div class="layer">
            <div>
                <!-- layer-header -->
                <header class="layer-header">
                    <h2 class="title">간편 쿼리 생성</h2>
                </header>
                <!-- layer-body -->
                <div class="layer-body">
                    <!-- form-fields -->
                    <div class="form-fields">
                        <!-- form-field -->
                        <div class="form-field">
                            <!-- form-row -->
                            <div class="form-row">
                                <label class="form-label">데이터 원본</label>
                            </div>
                            <!-- form-row -->
                            <div class="form-row">
                                <div class="form-select-box">
                                    <select v-model.trim="params.catalog" class="form-select" :disabled="catalogs.length === 0" @change="changeCatalog">
                                        <template v-if="catalogs.length > 0">
                                            <option v-for="(catalog, index) in catalogs" :value="catalog.name" :key="index">{{ catalog.name }}</option>
                                        </template>
                                        <option v-else value="">없음</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!-- form-field -->
                        <div class="form-field">
                            <!-- form-row -->
                            <div class="form-row">
                                <label class="form-label">데이터 베이스</label>
                            </div>
                            <!-- form-row -->
                            <div class="form-row">
                                <div class="form-select-box">
                                    <select v-model.trim="params.db" class="form-select" :disabled="databases.length === 0" @change="changeDatabase">
                                        <template v-if="databases.length > 0">
                                            <option v-for="(database, index) in databases" :value="database.name" :key="index">{{ database.name }}</option>
                                        </template>
                                        <option v-else value="">없음</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="form-field">
                            <!-- form-row -->
                            <div class="form-row">
                                <label class="form-label">테이블</label>
                            </div>
                            <!-- form-row -->
                            <div class="form-row">
                                <div class="form-select-box">
                                    <select v-model.trim="params.table" class="form-select" :disabled="tables.length === 0">
                                        <template v-if="tables.length > 0">
                                            <option v-for="(table, index) in tables" :value="table.name" :key="index">{{ table.name }}</option>
                                        </template>
                                        <option v-else value="">없음</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <!-- form-field -->
                        <div class="form-field">
                            <!-- form-row -->
                            <div class="form-row">
                                <label class="form-label">쿼리 편집</label>
                            </div>
                            <!-- form-row -->
                            <div class="form-row">
                                <textarea
                                    v-model.trim="params.query"
                                    rows="10"
                                    class="form-textarea"
                                    placeholder="SELECT * FROM “AwsDataCatalog”.“jdsw=safety”.”cctv” limit 10" />
                            </div>
                        </div>
                        <!-- form-field -->
                        <!--                        <div class="form-field">-->
                        <!--                            &lt;!&ndash; form-row &ndash;&gt;-->
                        <!--                            <div class="form-row">-->
                        <!--                                <label class="form-label">Output Bucket</label>-->
                        <!--                            </div>-->
                        <!--                            &lt;!&ndash; form-row &ndash;&gt;-->
                        <!--                            <div class="form-row">-->
                        <!--                                <div class="form-select-box">-->
                        <!--                                    <select class="form-select">-->
                        <!--                                        <option value="">Bucket 선택</option>-->
                        <!--                                    </select>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!-- //form-field -->
                    </div>
                    <!-- //form-fields -->
                </div>
                <!-- layer-actions -->
                <div class="layer-actions">
                    <button class="btn-action btn-outline" @click="close"><span class="text">취소</span></button>
                    <button class="btn-action btn-primary" :disabled="isLoading" @click="execQuery"><span class="text">생성</span></button>
                </div>
            </div>
        </div>
        <div class="layer-scrim"></div>
    </div>
</template>

<script>
import ApiService from '@/assets/js/api.service';
import {getItems, isSuccess, lengthCheck} from '@/assets/js/common.utils';
import {reactive, ref} from 'vue';
import {hideLoading, showLoading} from '@/assets/js/common.alert';

export default {
    name: 'AthenaQuery',
    props: {
        toggle: Boolean,
    },
    emits: ['update:toggle', 'execQuery'],
    setup(props, {emit}) {
        const isLoading = ref(false);
        const catalogs = ref([]);
        const databases = ref([]);
        const tables = ref([]);

        const params = reactive({
            catalog: '',
            db: '',
            query: '',
            table: '',
        });

        const getCatalogList = () => {
            ApiService.query('/v1/aws/athena/catalogs', {}).then(res => {
                if (lengthCheck(res)) {
                    catalogs.value = getItems(res);
                    params.catalog = catalogs.value[0].name;
                    getDatabaseList();
                }
            });
        };

        const getDatabaseList = () => {
            if (params.catalog) {
                ApiService.query(`/v1/aws/athena/${params.catalog}/databases`, {}).then(res => {
                    if (lengthCheck(res)) {
                        databases.value = getItems(res);
                        params.db = databases.value[0].name;
                        getTableList();
                    }
                });
            }
        };

        const getTableList = () => {
            if (params.catalog) {
                ApiService.query(`/v1/aws/athena/${params.catalog}/${params.db}/tables`, {}).then(res => {
                    if (lengthCheck(res)) {
                        tables.value = getItems(res);
                        params.table = tables.value[0].name;
                    }
                });
            }
        };

        const changeCatalog = () => {
            getDatabaseList();
        };

        const changeDatabase = () => {
            getTableList();
        };

        const execQuery = () => {
            showLoading();
            ApiService.post(`/v1/aws/athena/queries`, params)
                .then(res => {
                    hideLoading();
                    if (isSuccess(res)) {
                        emit('execQuery');
                        emit('update:toggle', false);
                    } else {
                        alert('쿼리 실행중 오류가 발생했습니다. 다시한번 시도해주세요.');
                    }
                })
                .catch(e => {
                    console.error(e);
                    hideLoading();
                    alert('쿼리 실행중 오류가 발생했습니다. 다시한번 시도해주세요.');
                });
        };

        const close = () => {
            emit('update:toggle', false);
        };

        getCatalogList();

        return {
            isLoading,
            catalogs,
            databases,
            tables,
            params,
            changeCatalog,
            changeDatabase,
            execQuery,
            close,
        };
    },
};
</script>
